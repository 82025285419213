import { useState, useEffect } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import {
  Logo,
  ImgMagicFinal,
  ImgPrimerPuesto,
  ImgTercerPuesto,
  ImgSegundoPuesto,
  RightIcon,
} from "../../assets";
import { getScoreApi } from "../../helpers/crush";
import { useToken } from "../../context/TokenContext";
import { api } from "../../helpers/api";

export const Score = () => {
  const [score, setScore] = useState([]);
  const { code } = useParams();
  const { push } = useHistory();
  const { token } = useToken();
  const [primero, setPrimero] = useState("");
  const [segundo, setSegundo] = useState("");
  const [tercero, setTercero] = useState("");

  useEffect(() => {
    if (!token) {
      push("/");
    }

    const getScore = async () => {
      const res = await getScoreApi(code, token);
      if (res.status == "success") setScore(res);
    };

    getScore();
  }, []);

  useEffect(() => {
    if (score.usuarios) {
      let winners = score.usuarios.sort((a, b) => (a.ideas > b.ideas ? -1 : 1));
      setPrimero(winners[0]);
      setSegundo(winners[1]);
      setTercero(winners[2]);
    }

    return () => {
      localStorage.removeItem("crushToken");
      localStorage.removeItem("adm");
    };
  }, [score]);

  return (
    <>
      <div className="ideacion__header">
        <img src={Logo} alt="" width="200px" height="auto" className="logo" />
      </div>
      <div className="score">
        <div className="score__container">
          <h1>¡Felicitaciones!</h1>
          <div>
            <h2>Se generaron</h2>
            <div className="score__ideas">
              <p>{score.ideas_totales}</p>
              <img src={ImgMagicFinal} alt="img-magic" />
            </div>
            <h4 className="score__text">ideas</h4>
            <div className="score__content_puestos">
              <div className="score__puesto2">
                <p>{segundo && segundo.name}</p>
                <img src={ImgSegundoPuesto} alt="" />
              </div>
              <div className="score__puesto1">
                <p>{primero && primero.name}</p>
                <img className="primero" src={ImgPrimerPuesto} alt="" />
              </div>
              <div className="score__puesto3">
                <p>{tercero && tercero.name}</p>
                <img src={ImgTercerPuesto} alt="" />
              </div>
            </div>
          </div>
          <div className="score__buttons_content">
            <a
              className="score__button"
              href={`${api}downloadExcel?code=${code}&token=${token}`}
              target="_blank"
            >
              Descargar en excel
            </a>
            <Link to="/" className="score__return">
              Volver a jugar <img src={RightIcon} alt="" />
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};
