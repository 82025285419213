import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom'
import Header from '../../components/layouts/Header';
import { useCrush } from "../../context/CrushContext";

export const Reto = () => {
  const [ retoValue, setRetoValue ] = useState('')

  const { setReto } = useCrush()
  const { push } = useHistory()

  const saveValue = () => {
    setReto(retoValue)
    push('/variables')
  }

  return (
    <section className="reto">
          <Header step={1} /> 
        <div className="reto__container">
          <div className="reto__input">
            <label htmlFor="reto" className="reto__label">¿Cómo podríamos...</label>
            <textarea  
                className="reto__textarea"
                name="reto"
                cols="40" 
                rows="3"
                placeholder="Ej: Resolver igualdad y paz en el mundo"
                onKeyUp={(e) => setRetoValue(e.target.value)}
            ></textarea>
        </div>
            <button className="reto__button" disabled={!retoValue} onClick={saveValue}>Continuar</button>          
        </div>
      
    </section>
  )
}
