import axios from 'axios'
import { api } from './api'

export const getIdeaApi = async(code, token) => {
    try {
        const res = await axios.get(`${api}listCrushIdea?code=${code}&token=${token}`)
        return res.data
    } catch (error) {
        return error.response.data
    }
}

export const saveIdeaApi = async(code, token, funcion_id, variable, idea) => {
    try {
        const res = await axios.post(`${api}storeIdeaCi`,{
            'code': code,
            'token': token,
            'funcion_id': funcion_id,
            'variable': variable,
            'idea': idea
        })
        return res.data
    } catch (error) {
        return error.response.data
    }
}

export const saveScamperApi = async(code, token, idea_id, scamper_id, idea) => {
    try {
        const res = await axios.post(`${api}storeIdeaTi`,{
            'code': code,
            'token': token,
            'idea_id': idea_id,
            'scamper_id': scamper_id,
            'idea': idea
        })
        return res.data
    } catch (error) {
        return error.response.data
    }
}

export const setFinalIdeaApi = async(code, token) => {
    try {
        const res = await axios.post(api+'storeIdeaFinal',{
            'code': code,
            'token': token
        })
        return res.data
    } catch (error) {
        return error.response.data
    }

}