// import { ImgIdeacion } from "../assets/index";

import {ChangeIcon} from "../assets";
import React from "react";

const IdeaCreator = (props) => {
  const { variable, funcion, reto, change } = props;

  return (
    <div className="ideacionscrumper__wrapper">
      <div className='ideacionscrumper__text'>
        <h3 className="ideacionscrumper__subtitle">
          Imagina que <b>{variable}</b> pudiera <b>{funcion}</b>
        </h3>
        <div className="ideacion__content_button">
          <button onClick={()=>change()} className="ideacion__change"> <img src={ChangeIcon}/>&nbsp;Cambiar frase</button>
        </div>
        <h2 className="ideacionscrumper__title">
          ¿Cómo podríamos <b>{reto}</b>?
        </h2>
      </div>
    </div>
  );
};

export default IdeaCreator;
