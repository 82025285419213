import { createContext, useContext, useState } from "react";

const appTokenContext = createContext()

export const TokenProvider = (props) => {
    const [ token, setToken ] = useState('')

    const value = [ token, setToken ]

    return <appTokenContext.Provider value={value}>
        {props.children}
    </appTokenContext.Provider>
}

export const useToken = () => {
    const [ token, setToken ] = useContext(appTokenContext)

    return { token, setToken }
}