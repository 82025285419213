import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import Header from "../../components/layouts/Header";
import { useCrush } from "../../context/CrushContext";

export const Tiempo = () => {
  const [ time, setTime ] = useState(3)
  const { setDuracion } = useCrush()
  const { push } = useHistory()

  const moreTime = () => {
    let newTime = time 
    newTime++
    setTime(newTime)
  }
  const lessTime = () => {
    let newTime = time 
    newTime--
    if(time>1) setTime(newTime)
  }

  const saveTime = () => {
    let cero = time < 10 ? '0' : ''
    let timeFormat = `00:${cero}${time}:00`
    setDuracion(timeFormat)
    push('/compartir')
  }
  
  return (
    <section className="tiempo">
      <div className="tiempo__container">
      <Header step={4} />
        <h2 className="tiempo__title">¿Cuánto tiempo quieres trabajar en este reto?</h2>
        <div className="tiempo__change">
          <span className="tiempo__input" onClick={()=>lessTime()}>
            -
          </span>
          <div className="tiempo__number">
           <p>{time < 10 && '0'}{time}:00</p>
          </div>
          <span className="tiempo__input" onClick={()=>moreTime()}>
            +
          </span>
        </div>
            <button className="variables__button" onClick={()=>saveTime()}>
            Siguiente</button>
      </div>
    </section>
  )
}
