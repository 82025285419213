import {ChangeIcon} from "../assets";
import React from "react";

const IdeaScrumper = ({verbo, idea, change}) => {
    return (
        <div>
            <div className="ideacion__textnew">
                <p>{idea}</p>
              </div>
              <h2 className="ideacion__title">Si pudieras <b>{verbo}</b> algún elemento de la idea ¿Cómo sería la nueva idea?</h2>
            <div className="ideacion__content_button">
                <button onClick={()=>change()} className="ideacion__change"> <img src={ChangeIcon}/>&nbsp;Cambiar frase</button>
            </div>
        </div>
    );
}

export default IdeaScrumper;