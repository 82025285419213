import React from 'react';
import './styles/styles.scss'
import { AppRouter } from './router/AppRouter';
function App() {
  return (
    <AppRouter>
    </AppRouter>
  );
}

export default App;
