import { createContext, useContext, useState } from "react";

const appCrushContext = createContext()

export const CrushProvider = (props) => {
    const [ reto, setReto ] = useState('')
    const [ variables, setVariables ] = useState('')
    const [ perspectiva, setPerspectiva ] = useState('')
    const [ duracion, setDuracion ] = useState('')

    const value = [
        reto, setReto,
        variables, setVariables,
        perspectiva, setPerspectiva,
        duracion, setDuracion
    ]

    return <appCrushContext.Provider value={value}>
            {props.children}
        </appCrushContext.Provider>

}

export const useCrush = () => {
    const [
        reto, setReto,
        variables, setVariables,
        perspectiva, setPerspectiva,
        duracion, setDuracion
    ] = useContext(appCrushContext);

    return {
        reto, setReto,
        variables, setVariables,
        perspectiva, setPerspectiva,
        duracion, setDuracion
    }
}