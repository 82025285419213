import { ImgNone, ImgMagic } from "../assets/index";

const PropositoCard = ({ value, img, setValue, tag, name }) => {
  return (
    <div>
      <input
        type="radio"
        id={value}
        name={name}
        className="proposito__input"
        onChange={()=>setValue(value)}
      />
      <label className="proposito__card" htmlFor={value}>
        <div className="proposito__card_flex">
          <img src={img || ImgNone} alt="proposito" />
          <h3>{tag}</h3>
        </div>
        <img src={ImgMagic} className="proposito__card_magic" />
      </label>
    </div>
  );
};

export default PropositoCard;
