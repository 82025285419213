import React, { useState, useEffect } from 'react'
import {  ImgProposito, Logo } from '../../assets';
import { useParams, useHistory } from 'react-router-dom'
import PropositoCard from '../../components/PropositoCard';
import { setUserApi } from '../../helpers/user'
import Loader from "../../components/Loader";
import { useToken } from "../../context/TokenContext";
import toast from "react-hot-toast";
import { ImgCreador, ImgTransformador } from "../../assets/index";

export const NombreInicial = () => {
  const { code } = useParams()
  const [ name, setName ] = useState('');
  const [ rol, setRol ] = useState(0)
  const [ isLoading, setIsLoading ] = useState(false)
  const { push } = useHistory()
  const { setToken } = useToken()

  //verifica si el token está en localstorage y lo setea al context, luego redirige a la sala de espera
  useEffect(() => {
    const token = localStorage.getItem('crushToken')
    if(token){  
      push(`/salaespera/${code}`)
      setToken(token)
    }
  },[isLoading])

  //guarda el usuario en la DDBB y lo agrega al localstorage
  const saveUser = async() => {
    setIsLoading(true)
    const adm = localStorage.getItem('adm')
    
      const res = await setUserApi(code, rol, name, adm)
      if(res.status == 'error'){
        toast.error(res.message)
        if(res.message == 'La sesión ya finalizó.'){
          push('/')
        }
        setIsLoading(false)
        return
      }
      if(res.status == 'success'){
        localStorage.setItem('crushToken',res.data.token)
        setIsLoading(false)
      }
      else{
        localStorage.removeItem('adm')
        localStorage.removeItem('crushToken')
      }
  }

  return (
    <section className="nombreinicial">
        <div className="nombreinicial__container">
        <img src={Logo} alt="logo" className="nombreinicial__logo m-0"/>        
          <h3 className="nombreinicial__subtitle m-0">Soluciones innovadoras y veloces</h3>
          <h2 className="nombreinicial__title">¿Cuál es tu nombre?</h2>
          <h3 className="nombreinicial__reto">Con este nombre o nickname aparecerás durante toda la sesión</h3>
          <div className="nombreinicial__input">
            <input type="text"
            className="nombreinicial__inputtext"
            placeholder="Ej: Da Vinci" onKeyUp={(e)=>setName(e.target.value)}/>
          </div>
          <h2 className="nombreinicial__title2">Quiero aportar más como</h2>
          <div className="nombreinicial__grid_content">
            <PropositoCard setValue={setRol} name="nombre" value={1} tag="Creador de ideas" img={ImgCreador}/>
            <PropositoCard setValue={setRol} name="nombre" value={2} tag="Transformador de ideas" img={ImgTransformador}/>
          </div>
            <button onClick={()=>saveUser()} className="nombreinicial__button" disabled={!(name&&rol) || isLoading }> {isLoading ? <Loader/>: 'Siguiente'} </button>
        </div>
    </section>
  )
}
