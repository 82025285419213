import { useState, useEffect } from 'react';
import { Logo,TimeIcon } from '../../assets'

const IdeacionHeader = ({setFinish, globalTime, start}) => {
  const [ time, setTime ] = useState(0)
  const [ seconds, setSeconds ] = useState(0)
  const [ isFirst, setIsFirst ] = useState(true)

  useEffect(() => {
    if(globalTime){
      let timeArr = globalTime.split(':')
      if(timeArr[0] == '-00'){
        setTime(0)
        setSeconds(0)
        return
      }
      setTime(parseInt(timeArr[1]))
      setSeconds(parseInt(timeArr[2]))
    }
  },[start])
  
  useEffect(()=>{
    if(time > 0 || seconds > 0){
    setTimeout(() => {
      if(seconds > 0){
          setSeconds(seconds - 1) 
        }
        else{
          setSeconds(59)
          setIsFirst(false)
        }
      }, 1000);
    }
    if(time == 0 && seconds == 0) setFinish(true)
    else setFinish(false)
    
  })
  
  useEffect(() => {
    if(!isFirst){
      if(time > 0){
        if(seconds == 59){
          setTime(time - 1)
        }
      }
    }
  },[seconds, isFirst])

    return (
        <div className="ideacion__header">
        <img src={Logo} alt="" width="200px" height="auto" className="logo"/>
        <h2 className="ideacion__title_head">¡Inspírate!</h2>
        <div className="time">
          <img src={TimeIcon} alt="" />
          <p>{time < 10 && '0'}{time}:{seconds < 10 && '0'}{seconds}</p>
        </div>
      </div>
    );
}

export default IdeacionHeader;