import { api } from "./api";
import axios from "axios";

export const setUserApi = async(code, rol, nombre, mod) => {
  let method = mod == 1 ? "storeUserCreated" : "storeUser";
  try {
    const res = await axios.post(api + method,{
        'code': code,
        'rol': rol,
        'nombre': nombre
    });
    return res.data
  } catch (error) {
    return error.response.data
  }
};

export const getSessionList = async (code, token) => {
  try {
    const res = await axios.get(`${api}listCrushUser?code=${code}&token=${token}`)
    return res.data
  } catch (error) {
    return error.response.data
  }
}

export const getFinalList = async (code, token) => {
  try {
    const res = await axios.get(`${api}listCrushUserStop?code=${code}&token=${token}`)
    return res.data
  } catch (error) {
    return error.response.data
  }
}