import { useEffect, useState } from "react";

const ChipVariable = ({ value, remove, color }) => {
  return (
    <div className="Chip">
        <div
          className="Chip__content"
          style={{ background: color.bg, color: color.color }}
        >
          {value}
          <button onClick={() => remove(value)}>
            <svg
              width="26"
              height="26"
              viewBox="0 0 26 26"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="13" cy="13" r="13" fill={color.color} />
              <path
                d="M7 17.0666L8.64394 18.9648L19.1125 9.89815L17.4686 8.00001L7 17.0666Z"
                fill="white"
              />
              <path
                d="M19.1125 17.0666L17.4686 18.9648L7.00004 9.89815L8.64398 8.00001L19.1125 17.0666Z"
                fill="white"
              />
            </svg>
          </button>
        </div>
    </div>
  );
};

export default ChipVariable;
