import React, { useEffect, useState } from "react";
import { CopyIcon } from "../../assets";
import { useHistory } from "react-router-dom";
import Header from "../../components/layouts/Header";
import { createSessionApi } from "../../helpers/crush";
import { useCrush } from ".././../context/CrushContext";
import Loader from "../../components/Loader";
import Clipboard from "react-clipboard.js";
import toast from "react-hot-toast";

export const Compartir = () => {
  const { reto, variables, perspectiva, duracion } = useCrush();
  const [code, setCode] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const { push } = useHistory();

  console.log(code)

  useEffect(() => {
    const initSession = async () => {
      try {
        if (reto && variables && perspectiva && duracion) {
          const res = await createSessionApi(
            reto,
            variables,
            perspectiva,
            duracion
          );
          if (res.status === "success") setCode(res.data.code);
          localStorage.setItem("adm", 1);
          setIsLoading(false);
        }
      } catch (error) {
        throw error;
      }
    };
    initSession();
  }, []);

  const copyUrl = () => {
    toast.success("Url copiada");
  };

  return (
    <section className="compartir">
      <div className="compartir__container">
        <Header step={5} />
        <h2 className="compartir__title">
          Comparte el siguiente enlace con los participantes
        </h2>
        <div className="compartir__enlace_container">
          <div className="compartir__content">
            <div className="compartir__text">
              <p>Copia esto</p>
            </div>

            <div className="compartir__url">
              <span id="url">
                {isLoading ? (
                  <Loader />
                ) : (
                  `${window.location.host}/go/${code}`
                )}
              </span>
              <Clipboard
                data-clipboard-text={`${window.location.host}/go/${code}`}
                onClick={copyUrl}
              >
                <img src={CopyIcon} alt="" />
              </Clipboard>
            </div>
          </div>
        </div>
        <button
          className="variables__button"
          disabled={isLoading}
          onClick={() => push(`/go/${code}`)}
        >
          Empezar
        </button>
      </div>
    </section>
  );
};
