import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Header from "../../components/layouts/Header";
import ChipVariable from "../../components/ChipVariable";
import { useCrush } from "../../context/CrushContext";
import toast from 'react-hot-toast';

const styles = [
  {
    bg: "#DDFEFD",
    color: "#103F74",
  },
  {
    bg: "#FEF7D4",
    color: "#794608",
  },
  {
    bg: "#FEE9D2",
    color: "#79050A",
  },
];

export const Variables = () => {
  const [varValue, setVarValue] = useState([]);
  const { reto, setVariables } = useCrush()
  const { push } = useHistory()

  const setValue = (e) => {
    e.preventDefault()
    if (varValue.length != 4) {
      const input = document.getElementById("variableInput");
      if(input.value){
        let newVatiable = { name: input.value};
        newVatiable.color = randomColor()
        if(newVatiable && newVatiable.color){
          let value = [...varValue];
          value.push(newVatiable);
          setVarValue(value);
          input.value = "";
          input.focus();
        }
      }
      return
    }
    toast.error('Puedes escribir cuatro variables como máximo');
  };

  const remove = (value) => {
    let newArr = [...varValue];
    let index = varValue.indexOf(value)
    newArr.splice(index, 1)
    setVarValue(newArr)
  }

  const saveVariables = () => {
    let varArr = varValue.map(item => item.name)
    const value = varArr.toString()
    setVariables(value)
    push('/proposito')
  }

  const randomColor = () => {
    let random = Math.round(Math.random() * styles.length - 1);
    return styles[random]
  };

  return (
    <section className="variables">
      <div className="variables__container">
        <Header step={2} />
        <h2 className="variables__title">
          ¿Cuáles son las variables que intervienen en el reto?
        </h2>
        <h3 className="variables__reto">
          Reto: {reto}
        </h3>
        <div className="variables__content_input">
          <form className="variables__input" onSubmit={setValue} >
            <input
              type="text"
              className="variables__inputtext"
              id="variableInput"
              placeholder="Ej: las ventas"
            />
            <button className="variables__agregar">
              Agregar
            </button>
          </form>
          <div>
            <h4 className="variables__count">{varValue.length} de 4</h4>
          </div>
        </div>
        <div className="variables__grid__variables">
            { varValue &&
              varValue.map((chip, index) => <ChipVariable key={index} value={chip.name} remove={remove} color={chip.color}/>)
            }
        </div>
        <button className="variables__button" disabled={!varValue.length} onClick={saveVariables}>
          Siguiente
        </button>
      </div>
    </section>
  );
};
