import React, { useEffect, useState } from "react";
import { ImgAleatoria } from "../../assets";
import { useHistory } from "react-router-dom";
import Header from "../../components/layouts/Header";
import PropositoCard from "../../components/PropositoCard";
import { useCrush } from "../../context/CrushContext";
import { getPerspectives } from "../../helpers/crush";
import Loader from "../../components/Loader";

export const Proposito = () => {
  const [perspectives, setPerspectives] = useState([]);
  const [isLoading, setIsloading] = useState(true);
  const [propositoValue, setPropositoValue] = useState("");
  const { setPerspectiva } = useCrush();
  const { push } = useHistory();

  useEffect(() => {
    const initPerpective = async () => {
      const res = await getPerspectives();
      setPerspectives(res);
      setIsloading(false);
    };
    initPerpective();
  }, []);

  const saveProposito = () => {
    if (propositoValue) {
      setPerspectiva(propositoValue);
      push("/tiempo");
    }
  };

  return (
    <section className="proposito">
      <div className="proposito__container">
        <Header step={3} />
        <h2 className="proposito__title">
          Quiero resolver el problema con una...
        </h2>

        <div className="proposito__grid_content">
          {isLoading ? (
            <Loader />
          ) :
            perspectives.map((per) => (
              <PropositoCard
                key={per.id}
                name="proposito"
                setValue={setPropositoValue}
                value={per.id}
                tag={per.name}
                img={per.icon}
              />
            ))
            }
        </div>
        <button
          onClick={() => saveProposito()}
          className="variables__button"
          disabled={!propositoValue}
        >
          Siguiente
        </button>
      </div>
    </section>
  );
};
