import React, { useEffect, useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import { ChangeIcon } from '../../assets'
import IdeaScrumper from '../../components/IdeaScrumper'
import IdeaCreator from '../../components/IdeaCreator'
import IdeacionHeader from '../../components/layouts/IdeacionHeader'
import { getIdeaApi, saveIdeaApi, saveScamperApi, setFinalIdeaApi } from "../../helpers/idea";
import { useToken } from "../../context/TokenContext";
import Loader from '../../components/Loader'
import toast from "react-hot-toast";

export const Ideacion = () => {
  const { token } = useToken()
  const { code } = useParams()
  const { push } = useHistory()

  const [ isStart, setIsStart ] = useState(false)
  const [ type, setType ] = useState(1)
  const [ inspiration, setInspiration ] = useState([])
  const [ idea, setIdea ] = useState('')
  const [ finish, setFinish ] = useState(false)
  const [ isLoading, setIsloading ] = useState(true)

  useEffect(() => {
    if(!token){
      push(`/go/${code}`)
      toast.error('Usuario inválido')
    }
    getIdea()
  },[])


  const getIdea = async() => {
    setIsloading(true)
    const res = await getIdeaApi(code, token)
    if(res.status == 'success'){
      setType(res.session.type)
      setInspiration(res.session)
      if(res.session.type == 2){
        document.getElementById('ideaInput').value = res.session.sentence
        setIdea(res.session.sentence)
      }
    }
    setIsStart(true)
    setIsloading(false)
  }

  const saveIdea = async() => {
    if(idea.length >=100){
    document.querySelector('textarea').value = ''
    document.querySelector('textarea').focus()
    setIsloading(true)
      let res
      if(type == 1){
        res = await saveIdeaApi(code, token, inspiration.funcion_id, inspiration.variable, idea)
      }
      else{
        res = await saveScamperApi(code, token, inspiration.idea_id, inspiration.scamper_id, idea)
      }
      if(res.status = 'success'){
        toast.success('Idea Guardada')
        setIdea('')
        if(!finish){
          getIdea()
          return
        }
        await setFinalIdeaApi(code, token)
        push(`/esperaFinal/${code}`)
      }
    }
    else{
      toast.error('Debes escribir como minimo 100 caracteres')
    }
  }

  return (
      <section className="ideacion">
        <IdeacionHeader setFinish={setFinish} globalTime={inspiration.time_remaining} start={isStart}/>
        <div className="ideacion__container">
          <div className="ideacion__frase">
          {isLoading ? <Loader/>
          : type == 1 ?
            <IdeaCreator change={getIdea} funcion={inspiration.funcion} reto={inspiration.reto} variable={inspiration.variable} />
            :<IdeaScrumper change={getIdea} idea={inspiration.sentence} verbo={inspiration.verbo} />
          }
          </div>
          <div className="ideacion__input">
            <textarea  
                className="ideacion__textarea"
                name="reto"
                cols="40" 
                rows="3"
                placeholder="¿Qué idea se te viene a la cabeza?"
                maxLength={800}
                onKeyUp={e => setIdea(e.target.value)}
                id="ideaInput"
            >
            </textarea>
            <div className='ideacion__length'>
              <b>{idea.length}/800</b>
            </div>
        </div> 
            <button className="ideacion__button" onClick={()=>saveIdea()} disabled={isLoading}>{isLoading? <Loader/> : 'Guardar idea'}</button>
        </div>
       
      </section>

  )
}
