import { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Logo } from "../../assets";
import Loader from "../../components/Loader";
import { useToken } from "../../context/TokenContext";
import { getFinalList } from "../../helpers/user";
import { setFinalSessionApi } from "../../helpers/crush";
import toast from "react-hot-toast";

const SalaEsperaFinal = () => {
  const [isAdmin, setIsAdmin] = useState(false);
  const [users, setUsers] = useState([]);
  const [ isLoading, setIsLoading ] = useState(true)

  const { code } = useParams();
  const { push } = useHistory();
  const { token } = useToken()

  useEffect(() => {
    const interval = setInterval(async () => {
      const res = await getFinalList(code, token);
      if (res.status == "error") {
        localStorage.removeItem("crushToken");
        toast.error(res.message)
        push("/");
        return;
      }
      if(res.status_session == 3) push(`/score/${code}`)
      if (res.status == "success") {
        if (res.is_created) setIsAdmin(true);
        setUsers(res.data);
        let faltantes = res.data.filter((item)=> item.status == 1)
        if(!faltantes.length){
          setIsLoading(false)
        }
      }
    }, 1000);

    
    return () => {
      clearInterval(interval);
    };
  }, []);
  
  const setFinalSession = async() => {
    setIsLoading(true)
    await setFinalSessionApi(code, token)
  }

  return (
    <div>
      <img src={Logo} alt="" width="200px" height="auto" className="logo-sala" />
      <section className="salaespera">
        <div className="salaespera__container">
          <h2 className="salaespera__title">Esperemos a los demás...</h2>
          <h3 className="salaespera__reto">Ya terminaron {users.filter(u => u.status == 0).length}/{users.length}</h3>
          <div className="salaespera__list">
            <ul>
              {users.map((user, index) => (
                <li key={index}>
                  <p className={user.status == 1 && 'inactive' } >{index + 1 + ".- " + user.name}</p>
                </li>
              ))}
            </ul>
          </div>
          <div className="salaespera__espera">
            {isAdmin
            ? (
              <button
                className="nombreinicial__button"
                onClick={()=>setFinalSession()}
                disabled={isLoading}
              >
                {
                  isLoading? <Loader/>
                  : 'Ver resultados'
                }
                
              </button>
            ) : (
              <>
                <Loader />
                <h4 className="salaespera__anfitrion">
                  {
                    isLoading ? 'Esperando a que los participantes terminen' : 'Esperando que el afitrión muestre los resultados'
                  }
                </h4>
              </>
            )}
          </div>
        </div>
      </section>
    </div>
  );
};

export default SalaEsperaFinal;
