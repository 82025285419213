import { useEffect, useState } from "react";

const ProgressBar = ({ step }) => {
  const [progress, setProgress] = useState();

  useEffect(() => {
    if (step) {
      let value = 100 / 5 * step;
      setProgress(value);
    }
  }, [step]);

  return (
    <div className="container">
      <div className="progress progress__striped">
        <p> { step < 5 ? step + ' de 5' : '¡Listo!'}</p>
        <div className="progress__content">
          <div
            className="progress__bar"
            style={{ width: `${progress}%` }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default ProgressBar;