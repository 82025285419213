import ProgressBar from "../ProgressBar";
import { Logo } from "../../assets/index";

const Header = ({ step }) => {
  return (
    <div>
      <img src={Logo} alt="logo-crush" className="logo m-0" />
      <h3 className="reto__subtitle m-0">Soluciones innovadoras y veloces</h3>
      <ProgressBar step={step} />
    </div>
  );
};

export default Header;
