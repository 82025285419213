import { api } from "./api";
import axios from "axios";

export const getPerspectives = async() => {
  try {
    const res = await axios.get(`${api}listPerspectives`)
    return res.data
  } catch (error) {
    return error.response.data
  }

}
export const createSessionApi = async (
  reto,
  variables,
  perspectiva,
  duracion
  ) => {
    try {
      const res = await axios.post(api + "storeCrush", {
        reto: reto,
        variables: variables,
        perspectiva: perspectiva,
        duracion: duracion,
      });
      return res.data;
  } catch (error) {
    throw error;
  }
};

export const startSessionApi = async (code, token) => {
    try {
        const res = await axios.post(api + 'storeCrushStart',{
            'code': code,
            'token': token
        })
        return res.data
    } catch (error) {
        throw error;
    }
};

export const setFinalSessionApi = async(code, token) => {
  try {
      const res = await axios.post(api+'storeCrushFinal',{
          'code': code,
          'token': token
      })
      return res.data
  } catch (error) {
      return error.response.data
  }
}

export const getScoreApi = async(code, token) => {
  try {
    const res = await axios.get(`${api}listCrushFinal?code=${code}&token=${token}`)
    return res.data
  } catch (error) {
    return error.response.data
  }
}