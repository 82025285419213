import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  // Redirect
} from "react-router-dom";
import { Compartir } from "../pages/Creation/Compartir";

// pages

import { Home } from "../pages/Creation/Home";
import { Proposito } from "../pages/Creation/Proposito";
import { Reto } from "../pages/Creation/Reto";
import { Tiempo } from "../pages/Creation/Tiempo";
import { Variables } from "../pages/Creation/Variables";
import { NombreInicial } from "../pages/Game/NombreInicial";
import { SalaEspera } from "../pages/Game/SalaEspera";
import { Ideacion } from "../pages/Game/Ideacion";
import { Score } from "../pages/Game/Score";
import { CrushProvider } from "../context/CrushContext";
import { TokenProvider } from "../context/TokenContext";
import { SessionProvider } from "../context/SessionContext";
import SalaEsperaFinal from "../pages/Game/SalaEsperaFinal";
import { Toaster } from "react-hot-toast";

export const AppRouter = () => {
  return (
    <div>
      <Router>
        <div>
          <Switch>
            <TokenProvider>
              <SessionProvider>
                <Route path="/" component={Home} exact />
                <CrushProvider>
                  <Route path="/reto" component={Reto} exact />
                  <Route path="/variables" component={Variables} exact />
                  <Route path="/proposito" component={Proposito} exact />
                  <Route path="/tiempo" component={Tiempo} exact />
                  <Route path="/compartir" component={Compartir} exact />
                </CrushProvider>
                <Route path="/go/:code" component={NombreInicial} exact />
                <Route path="/salaespera/:code" component={SalaEspera} exact />
                <Route path="/ideacion/:code" component={Ideacion} exact />
                <Route
                  path="/esperaFinal/:code"
                  component={SalaEsperaFinal}
                  exact
                />
                <Route path="/score/:code" component={Score} exact />
              </SessionProvider>
            </TokenProvider>
          </Switch>
          <Toaster position="bottom-center" />
        </div>
      </Router>
    </div>
  );
};
