import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Logo } from "../../assets/index";
import { useHistory } from 'react-router-dom'
export const Home = () => {
  const { push } = useHistory()
  useEffect(() => {
    localStorage.removeItem("adm");
    localStorage.removeItem("crushToken");

    setTimeout(()=>{
      push('/reto')
    },2600)
  }, []);
  return (
    <section className="home">
      <div className="home__container">
        <div className="home__logo_complete">
          <img src={Logo} alt="logo-crush" className="home__logo m-0" />
          <h3 className="home__subtitle m-0">Soluciones innovadoras y veloces</h3>
        </div>
        <p className="home__text">
          La herramienta que te ayudará a generar más de <br />
          <b>50 ideas en menos de 10 minutos</b>
        </p>
        {/*<Link to="/reto">*/}
        {/*  <button className="home__button">Continuar</button>*/}
        {/*</Link>*/}
      </div>
    </section>
  );
};
