import { createContext, useContext, useState, useMemo } from "react";

const appSessionContext = createContext()

export const SessionProvider = (props) => {
    const [ time, setTime ] = useState(0)
    const value = [ time, setTime ]
    return <appSessionContext.Provider value={value}>
        { props.children }
    </appSessionContext.Provider>
}

export const useSession = () => {
    const [ time, setTime ] = useContext(appSessionContext)
    return { time, setTime }
}