import { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Logo } from "../../assets";
import Loader from "../../components/Loader";
import { getSessionList } from "../../helpers/user";
import { startSessionApi } from "../../helpers/crush";
import { useSession } from '../../context/SessionContext'
import toast from "react-hot-toast";

export const SalaEspera = () => {
  const [isAdmin, setIsAdmin] = useState(false);
  const [users, setUsers] = useState([]);
  const [token, setToken] = useState("");
  const [isLoading, setIsloading] = useState(false);

  const { code } = useParams();
  const { push } = useHistory();

  useEffect(() => {
    const localToken = localStorage.getItem("crushToken");
    if (!localToken) {
      push(`/go/${code}`);
      return;
    }
    setToken(localToken);
  }, []);

  useEffect(() => {
    const interval = setInterval(async () => {
      const res = await getSessionList(code, token);
      if (res.status == "error") {
        localStorage.removeItem("crushToken");
        toast.error(res.message)
        push("/");
        return;
      }
      if (res.status == "success") {
        if (res.status_session == 2) {
          push(`/ideacion/${code}`)
        }
        if (res.is_created) setIsAdmin(true);
        setUsers(res.data);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [token]);

  const startSession = async () => {
    setIsloading(true);
    const res = await startSessionApi(code, token);
    if(res.status == 'error'){
      toast.error(res.message)
      setIsloading(false)
    }
  };

  return (
    <div>
      <img src={Logo} alt="" width="200px" height="auto" className="logo-sala" />
      <section className="salaespera">
        <div className="salaespera__container">
          <h2 className="salaespera__title">Esperemos a que todos entren...</h2>
          <h3 className="salaespera__reto">{users.length} Jugadores</h3>
          <div className="salaespera__list">
            <ul>
              {users.map((user, index) => (
                <li key={index}>
                  <p>{index + 1 + ".- " + user.name}</p>
                </li>
              ))}
            </ul>
          </div>
          <div className="salaespera__espera">
            {isAdmin ? (
              <button
                className="nombreinicial__button"
                onClick={() => startSession()}
                disabled={isLoading}
              >
                {isLoading ? <Loader /> : "¡Estamos completos, iniciar!"}
              </button>
            ) : (
              <>
                <Loader />
                <h4 className="salaespera__anfitrion">
                  Esperando a que el anfitrión comience Crush :)
                </h4>
              </>
            )}
          </div>
        </div>
      </section>
    </div>
  );
};
